import * as React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { Typography, Stack, Skeleton } from "@mui/material";
import { useQuery } from '@tanstack/react-query';

// Components
import Header from "../components/common/header/Header";
import Footer from "../components/common/Footer/Footer";
import ProductCategories from "../components/products/ProductCategories";
import ProductBrands from "../components/products/ProductBrands";
import ProductItem from "../components/products/ProductItem";
import ProductCriteria from "../components/products/ProductCriteria";
import CompareProducts from "../components/products/CompareProducts";
import LocalProductItem from "../components/products/LocalProductItem";
import CustomBreadcrumbs from "../components/common/Breadcrumbs/Breadcrumbs";

// Services & Interfaces
import { fetchArticles, fetchGenericArticleFacets } from "../services/tecdocService";
import { Article } from "../interfaces/Article";
import { CriteriaFilters } from "../interfaces/CriteriaFilters";
import theme from "../theme";

const ProductSearchPage = () => {
  // State management
  const [categoryId, setCategoryId] = React.useState<number | null>(null);
  const [brandId, setBrandId] = React.useState<number | null>(null);
  const [linkageTargetId, setLinkageTargetId] = React.useState<number | null>(null);
  const [linkageTargetType, setLinkageTargetType] = React.useState<string | null>(null);
  const [assemblyGroupNodeIds, setAssemblyGroupNodeIds] = React.useState<number | null>(null);
  const [criteriaFilters, setCriteriaFilters] = React.useState<CriteriaFilters[] | null>(null);
  const [comparisonArticles, setComparisonArticles] = React.useState<Article[]>([]);
  const [page, setPage] = React.useState(1);
  const [keyword, setKeyword] = React.useState('');

  // URL params effect
  React.useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    
    const categoryIdParam = urlParams.get("category");
    const brandIdParam = urlParams.get("brand");
    const keywordParam = urlParams.get("keyword");
    const linkageTargetTypeParam = urlParams.get('linkageTargetType');
    const linkageTargetIdParam = urlParams.get('linkageTargetId');
    const assemblyGroupNodeIdsParam = urlParams.get("assemblyGroupNodeIds");

    if (categoryIdParam) setCategoryId(parseInt(categoryIdParam, 10));
    if (brandIdParam) setBrandId(parseInt(brandIdParam, 10));
    if (keywordParam) setKeyword(keywordParam);
    if (linkageTargetTypeParam) setLinkageTargetType(linkageTargetTypeParam);
    if (linkageTargetIdParam) setLinkageTargetId(parseInt(linkageTargetIdParam, 10));
    if (assemblyGroupNodeIdsParam) setAssemblyGroupNodeIds(parseInt(assemblyGroupNodeIdsParam, 10));

    const criteriaParams: CriteriaFilters[] = [];
    urlParams.forEach((value, key) => {
      if (key === "criteria") {
        const [criteriaId, rawValue] = value.split(":");
        criteriaParams.push({
          criteriaId: parseInt(criteriaId, 10),
          rawValue,
        });
      }
    });
    setCriteriaFilters(criteriaParams);
  }, []);

  // React Query hooks
  const { data: genericArticleFacets } = useQuery({
    queryKey: ['genericArticleFacets', keyword, brandId, linkageTargetType, linkageTargetId, assemblyGroupNodeIds],
    queryFn: () => fetchGenericArticleFacets(keyword, {
      brand: brandId,
      linkageTargetType,
      linkageTargetId,
      assemblyGroupNodeIds
    }),
    enabled: !!(keyword || brandId || assemblyGroupNodeIds)
  });

  const { data: articlesData, isLoading } = useQuery({
    queryKey: ['articles', keyword, categoryId, brandId, linkageTargetType, linkageTargetId, assemblyGroupNodeIds, criteriaFilters, page],
    queryFn: () => fetchArticles(keyword, {
      category: categoryId,
      brand: brandId,
      linkageTargetType,
      linkageTargetId,
      assemblyGroupNodeIds,
      criteria: criteriaFilters,
      page
    }),
    enabled: !!(keyword || categoryId || brandId || assemblyGroupNodeIds)
  });

  // Memoized callbacks
  const updateCategories = React.useCallback((newCategoryId: number) => {
    setCategoryId(newCategoryId);
    setPage(1);
  }, []);

  const updateCriteriaFilters = React.useCallback((criteriaId: number, rawValue: string, isChecked: boolean) => {
    setCriteriaFilters(prevFilters => {
      const updatedFilters = prevFilters ? [...prevFilters] : [];
      if (isChecked) {
        updatedFilters.push({ criteriaId, rawValue });
      } else {
        return updatedFilters.filter(
          filter => filter.criteriaId !== criteriaId || filter.rawValue !== rawValue
        );
      }
      return updatedFilters;
    });
    setPage(1);
  }, []);

  const updateComparisonArticles = React.useCallback((article: Article, isChecked: boolean) => {
    setComparisonArticles(prevArticles => 
      isChecked 
        ? [...prevArticles, article]
        : prevArticles.filter(a => a !== article)
    );
  }, []);

  // Render functions
  const renderArticles = React.useMemo(() => {
    if (!articlesData?.articles || articlesData.articles.length === 0) {
      return (
        <Typography variant="h5" textAlign="center" mt={10}>
          No products found!
        </Typography>
      );
    }

    return articlesData.articles.map((article, index) => (
      article.tec_doc_article ? (
        <ProductItem 
          key={`${article.articleNumber}-${index}`}
          article={article} 
          onToggle={updateComparisonArticles}
        />
      ) : (
        <LocalProductItem 
          key={`${article.articleNumber}-${index}`}
          article={article}
        />
      )
    ));
  }, [articlesData, updateComparisonArticles]);

  const renderSkeleton = React.useMemo(() => (
    <Stack spacing={1} sx={{ mt: 2 }}>
      <Box sx={{
        flexGrow: 1,
        m: 2,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={12}>
            <Box>
              <Grid container spacing={1}>
                <Grid item xs={12} sm={12} md={7} sx={{paddingLeft: '0px !important', paddingTop: '0px !important'}}>
                  <CustomBreadcrumbs />
                </Grid>
                <Grid item xs={12} sm={12} md={5}>
                  <Box sx={{ display: 'flex', alignItems: 'center', pr: 1 }}>
                    <Box sx={{ flexGrow: 1, marginRight: 2 }}>
                      <Skeleton animation="wave" sx={{ width: '200px', height: '30px' }} />
                    </Box>
                    <Box sx={{ flexGrow: 3, marginRight: 2 }}>
                      <Skeleton animation="wave" sx={{ width: '200px', height: '30px' }} />
                    </Box>
                    <Box sx={{ flexGrow: 1 }}>
                      <Skeleton animation="wave" sx={{ width: '200px', height: '30px' }} />
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Box>
            <Box sx={{ mt: 4 }}>
              <ProductItem />
            </Box>
          </Grid>
        </Grid>
        <Stack spacing={2} sx={{ pt: 2 }}>
          <Skeleton animation="wave" sx={{ width: '100px' }} />
        </Stack>
      </Box>
    </Stack>
  ), []);

  return (
    <>
      <Header />
      <Box sx={{
        flexGrow: 1,
        m: 2,
        minHeight: '71vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={2.5}>
            <ProductCategories 
              genericArticles={genericArticleFacets || []} 
              onCategoryChange={updateCategories}
            />
            {categoryId !== null && articlesData?.groupedCriteria && (
              <ProductCriteria 
                groupedCriteria={articlesData.groupedCriteria}
                onCriteriaChange={updateCriteriaFilters}
              />
            )}
          </Grid>
          <Grid item xs={12} sm={12} md={9.5}>
            <Box sx={{
              bgcolor: "#f1f1f1",
              py: 1,
              px: 2,
              borderTop: `2px solid ${theme.palette.primary.dark}`,
            }}>
              <Grid container spacing={1}>
                  <Grid item xs={12} sm={12} md={5} sx={{paddingLeft: '0px !important'}}>
                    {articlesData?.assemblyGroupFacets && (
                      <CustomBreadcrumbs assemblyGroupFacets={articlesData.assemblyGroupFacets.counts} />
                    )}
                  </Grid>
                  <Grid item xs={12} md={7}>
                    <Box sx={{ display: 'flex', justifyContent: { xs: "flex-start", sm: "flex-end"}, pr: 1 }}>
                      <Box sx={{ mr:1, display: {xs: 'none', sm: 'block', md: 'block', lg: 'block'} }}>
                        <CompareProducts compareArticles={comparisonArticles}/>
                      </Box>
                      {articlesData?.dataSupplierFacets && (
                        <Box sx={{ mr:1 }}>
                          <ProductBrands dataSupplierFacets={articlesData.dataSupplierFacets} />
                        </Box>
                      )}
                    </Box>
                  </Grid>
                </Grid>

            </Box>
            <Box sx={{ mt: 4 }}>
              {isLoading ? renderSkeleton : renderArticles}
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Footer />
    </>
  );
};

export default ProductSearchPage;