import React, { useState } from 'react';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { verificationService } from '../../../services/articleVerificationService';
import {
  Box,
  Container,
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  LinearProgress,
  CircularProgress,
  Alert,
  Button,
} from '@mui/material';
import FileUpload from '../../../components/FileUpload';
import { TaskStatus } from '../../../interfaces/core/ArticleVerification';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

const ArticleVerificationPage: React.FC = () => {
  const queryClient = useQueryClient();
  const [activeTaskId, setActiveTaskId] = useState<string | null>(() => {
    return localStorage.getItem('verificationTaskId');
  });
  const [uploadProgress, setUploadProgress] = useState(0);

  const statusQuery = useQuery<TaskStatus, Error>({
    queryKey: ['verificationStatus', activeTaskId] as const,
    queryFn: () => verificationService.getVerificationStatus(activeTaskId!),
    enabled: !!activeTaskId,
    refetchInterval: (query) => {
      return query.state.data?.status === 'processing' ? 500 : false;
    },
    refetchOnMount: true,
    refetchOnWindowFocus: true,
    retry: false,
  });

  const handleFileUpload = async (file: File) => {
    try {
      setUploadProgress(0); // Reset progress
      const response = await verificationService.startVerification(file);
      setActiveTaskId(response.task_id);
      localStorage.setItem('verificationTaskId', response.task_id);
      
      queryClient.invalidateQueries({
        queryKey: ['verificationStatus', response.task_id]
      });

      // Simulate progress over 8 seconds
      const startTime = Date.now();
      const duration = 20000; // 20 seconds

      const updateProgress = () => {
        const elapsed = Date.now() - startTime;
        const progress = Math.min((elapsed / duration) * 100, 100);
        setUploadProgress(progress);

        if (elapsed < duration) {
          requestAnimationFrame(updateProgress);
        } else {
          window.location.reload();
        }
      };

      requestAnimationFrame(updateProgress);

    } catch (error) {
      console.error('Error uploading file:', error);
      setUploadProgress(0);
    }
  };

  const handleNewVerification = () => {
    setActiveTaskId(null);
    localStorage.removeItem('verificationTaskId');
    queryClient.removeQueries({
      queryKey: ['verificationStatus']
    });
  };

  const shouldShowUpload = !activeTaskId || 
    statusQuery.isError || 
    (statusQuery.error as any)?.response?.status === 404;

  return (
    <Container>
      {shouldShowUpload ? (
        <Box>
          <Typography variant="h4" gutterBottom>
            Article Verification
          </Typography>
          <FileUpload onFileSelect={handleFileUpload} />
        </Box>
      ) : (
        <Box>
          <Box display="flex" justifyContent="space-between" alignItems="center" mb={3}>
            <Typography variant="h4">
              {statusQuery.data?.status === 'completed' 
                ? 'Verification Complete'
                : 'Verification in Progress'}
            </Typography>
            {statusQuery.data?.status === 'completed' && (
              <Button 
                variant="contained" 
                color="primary" 
                onClick={handleNewVerification}
              >
                Start New Verification
              </Button>
            )}
          </Box>

          {!statusQuery.data ? (
            <CircularProgress />
          ) : statusQuery.error ? (
            <Alert severity="error">
              Error: Something went wrong
            </Alert>
          ) : (
            <>
              {statusQuery.data.status === 'processing' && (
                <Box sx={{ width: '100%', mb: 2 }}>
                  <LinearProgress 
                    variant="determinate" 
                    value={uploadProgress} 
                  />
                  <Typography variant="body2" sx={{ mt: 1, color: 'primary.main' }}>
                    Progress: {Math.round(uploadProgress)}%
                  </Typography>
                  <Typography variant="body2" sx={{ color: 'primary.main' }}>
                    Processed: {statusQuery.data.total_checked} of {statusQuery.data.total_rows}
                  </Typography>
                  <Typography variant="body2" sx={{ color: 'primary.main' }}>
                    Missing Articles: {statusQuery.data.total_missing || 0}
                  </Typography>
                </Box>
              )}

              {statusQuery.data.results && statusQuery.data.results.length > 0 && (
                <TableContainer 
                  component={Paper} 
                  sx={{ 
                    mt: 3,
                    maxHeight: 420,
                    '& .MuiTableCell-root': {
                      px: 2, 
                      py: 1,
                    }
                  }}
                >
                  <Table stickyHeader>
                    <TableHead>
                      <TableRow>
                        <TableCell>Article Number</TableCell>
                        <TableCell>Internal Number</TableCell>
                        <TableCell>Supplier ID</TableCell>
                        <TableCell>Manufacturer</TableCell>
                        <TableCell>Status</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {statusQuery.data.results.map((result) => (
                        <TableRow
                          key={`${result.articleNumber}-${result.dataSupplierId}`}
                        >
                          <TableCell>{result.articleNumber}</TableCell>
                          <TableCell>{result.internalNumber || "-"}</TableCell>
                          <TableCell>{result.dataSupplierId}</TableCell>
                          <TableCell>{result.mfrName}</TableCell>
                          <TableCell>
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                              {result.exists ? (
                                <>
                                  <CheckCircleOutlineIcon color="success" />
                                  <Typography color="success.main">
                                    Valid Article
                                  </Typography>
                                </>
                              ) : (
                                <>
                                  <ErrorOutlineIcon color="error" />
                                  <Typography color="error">
                                    Article Number or Supplier ID not found
                                  </Typography>
                                </>
                              )}
                            </Box>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              )}

              {statusQuery.data.status === 'error' && (
                <Alert severity="error" sx={{ mt: 2 }}>
                  Error: {statusQuery.data.error}
                </Alert>
              )}
            </>
          )}
        </Box>
      )}
    </Container>
  );
};

export default ArticleVerificationPage;