import * as React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import { useDispatch, useSelector } from "react-redux";
import {
  Alert,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CardMedia,
  IconButton,
  Popover,
  Typography,
  useMediaQuery,
} from "@mui/material";
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";
import Snackbar, { SnackbarOrigin } from "@mui/material/Snackbar";
import Fade from "@mui/material/Fade";
import Slide, { SlideProps } from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import LoadingButton from "@mui/lab/LoadingButton";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import theme from "../../theme";
import ProductTabs from "./productTabs/ProductTabs";
import Checkbox from "@mui/material/Checkbox";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import { Article } from "../../interfaces/Article";
import Skeleton from "@mui/material/Skeleton";
import { BasketItem } from "../../interfaces/core/BasketItem";
import {
  addProductToBasket,
  getLiveArticleState,
} from "../../services/core-api";
import { addToCart } from "../../store/reducers/basket";
import { useSnackbar } from "../common/SnackbarContext";

interface State extends SnackbarOrigin {
  openNotification: boolean;
}

function SlideTransition(props: SlideProps) {
  return <Slide {...props} direction="up" />;
}

const CustomIcon = styled(ContentCopyOutlinedIcon)({
  marginLeft: 10,
  marginRight: 0,
});

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: "transparent",
  color: "#000",
  boxShadow: "none",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
}));

interface PriceState {
  retailPrice: boolean;
}

interface RootState {
  priceReducer: PriceState;
}

const ProductItem = (props: any) => {
  const controllerRef = React.useRef<AbortController | null>(null);
  const { article, onToggle } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [erpProduct, setErpProduct] = React.useState<any | null>(null);
  const open = Boolean(anchorEl);
  const [openBasketNotification, setOpenBasketNotification] =
    React.useState(false);
  const [basketItem, setBasketItem] = React.useState<BasketItem | null>(null);
  const [value, setValue] = React.useState(1);
  const [isFavorite, setIsFavorite] = React.useState<Boolean | null>(null);
  const dispatch = useDispatch();
  const { showSnackbar } = useSnackbar();
  const [price, setPrice] = React.useState(0);
  const [price_2, setPrice_2] = React.useState(0);
  const [quantity, setQuantity] = React.useState(0);
  const [isloadingStock, setIsLoadingStock] = React.useState(true);
  const defaultImage = "./no-image.png";
  const isLoadingRef = React.useRef(false);
  const retailPrice = useSelector(
    (state: RootState) => state.priceReducer.retailPrice
  );

  React.useEffect(() => {
    const controller = new AbortController();
    controllerRef.current = controller;

    if (article && !isLoadingRef.current) {
      const productId = article.productId;
      const internalNumber = article.internalNumber;
      if (productId && internalNumber) {

        isLoadingRef.current = true;
        setIsLoadingStock(true);
        (async () => {
          try {
            const internalNumberModified = internalNumber.replace(/\//g, "*");
            if (controllerRef.current === controller) {
              const erpArticle = await getLiveArticleState(
                internalNumberModified
              );

              if (erpArticle) {
                setErpProduct(erpArticle);
                setPrice(erpArticle[0].CMSH);
                setPrice_2(erpArticle[0].CMSH1);
                article.price = erpArticle[0].CMSH;
                article.offerPrice = erpArticle[0].CMSH1;
                const total_quantity = erpArticle.reduce(
                  (acc: any, item: any) => acc + (item.GJENDJE || 0),
                  0
                );
                setQuantity(total_quantity);
                setValue(total_quantity > 0 ? 1 : 0);
              }
              setIsLoadingStock(false);
            }
          } catch (error) {
              setIsLoadingStock(false);
              setQuantity(-1);
              setPrice(0.0);
              setPrice_2(0.0);
              setValue(0);
              article.total_quantity = -1;
          }
        })();
      } else {
        setIsLoadingStock(false);
        setQuantity(-1);
      }
    }

    // Cleanup function
    return () => {
      controller.abort();
      controllerRef.current = null;
      isLoadingRef.current = false;
    };
  }, [article]);

  const handlePopoverOpen = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  // Add article to basket
  const addToBasket = (
    article: Article,
    newStateBasketNotification: SnackbarOrigin
  ) => {
    setStateBasketNotification({
      ...newStateBasketNotification,
      openNotification: true,
    });
    setOpenBasketNotification(true);
    let description = "";
    let articleCriteria = article.articleCriteria || [];
    articleCriteria.forEach((criteria: any) => {
      if (criteria?.criteriaDescription && criteria?.formattedValue) {
        description += `<b style="font-weight: 500">${criteria.criteriaDescription}</b>: ${criteria.formattedValue}; `;
      }
    });

    const item: BasketItem = {
      productId: article.productId ?? 0,
      internalNumber: article.internalNumber ?? "",
      articleNumber: article.articleNumber,
      mfrName: article.mfrName,
      dataSupplierId: article.dataSupplierId,
      image: article.images?.[0]?.imageURL200 ?? defaultImage,
      description: description,
      price: price,
      quantity: value,
      totalPrice: parseFloat((price * value).toFixed(2)),
      available: true,
    };

    setBasketItem(item);
    const user = localStorage.getItem("user");
    if (user) {
      const parsedUser = JSON.parse(user);
      const user_id = parsedUser.id;
      (async () => {
        try {
          await addProductToBasket(user_id, item);
          dispatch(addToCart(item));
          showSnackbar("Product added to basket successfully!", "success");
        } catch (error) {
          showSnackbar("Failed to add product to basket.", "error");
        }
      })();
    }
  };

  const hideBasketNotification = () => {
    setStateBasketNotification({
      ...stateBasketNotification,
      openNotification: false,
    });
  };

  const [stateBasketNotification, setStateBasketNotification] =
    React.useState<State>({
      openNotification: false,
      vertical: "top",
      horizontal: "center",
    });

  const { vertical, horizontal, openNotification } = stateBasketNotification;

  const handleChange = (event: any) => {
    if (event.target.value !== "" && parseInt(event.target.value) > 100) {
      setValue(200);
    } else if (parseInt(event.target.value) < 1) {
      setValue(1);
    } else {
      setValue(event.target.value);
    }
  };

  const [state, setState] = React.useState<{
    open: boolean;
    Transition: React.ComponentType<
      TransitionProps & {
        children: React.ReactElement<any, any>;
      }
    >;
  }>({
    open: false,
    Transition: Fade,
  });

  const handleClick =
    (
      Transition: React.ComponentType<
        TransitionProps & {
          children: React.ReactElement<any, any>;
        }
      >
    ) =>
    async () => {
      try {
        await navigator.clipboard.writeText(article.articleNumber);
        showSnackbar("Product article number copied!", "success");
      } catch (error) {
        showSnackbar("Failed to copy article number.", "error");
      }

      setState({
        open: false,
        Transition,
      });
    };

  const handleClose = () => {
    setState({
      ...state,
      open: false,
    });
  };

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onToggle(article, event.target.checked);
  };

  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <>
      {article ? (
        <>
          {isMobile ? (
            <>
              <Card sx={{ maxWidth: "100%", mb: 2 }}>
                <CardHeader
                  sx={{
                    backgroundColor: "#f1f1f1",
                    display: "flex",
                    alignItems: "center",
                    // Ensure the content area is flex container
                    "& .MuiCardHeader-content": {
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start", // Align items to the left
                      flexGrow: 1,
                      ml: 1, // Optional margin to space content from avatar
                    },
                    "& .MuiCardHeader-title": {
                      fontFamily: "Inter",
                      fontSize: "12px",
                      whiteSpace: "nowrap", // Prevent text wrapping
                    },
                    "& .MuiCardHeader-subheader": {
                      color: "red",
                      fontFamily: "Inter",
                      fontSize: "12px",
                      whiteSpace: "nowrap", // Prevent text wrapping
                    },
                    "& .MuiCardHeader-avatar": {
                      // Apply styles to the avatar slot
                      mr: 0,
                      minWidth: "40px",
                      "& .MuiButton-root": {
                        minWidth: "40px",
                        "&:focus, &:active": {
                          backgroundColor: "transparent",
                        },
                      },
                    },
                  }}
                  avatar={
                    <Button
                      sx={{
                        minWidth: "40px",
                        "&:focus, &:active": {
                          backgroundColor: "transparent",
                        },
                      }}
                      startIcon={<CustomIcon />}
                      onClick={handleClick(SlideTransition)}
                    ></Button>
                  }
                  action={
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: 1, // Spacing between elements
                      }}
                    >
                      {isloadingStock ? (
                        <Stack
                          direction="row"
                          spacing={2}
                          sx={{ width: "65px" }}
                        >
                          <LoadingButton
                            loading
                            variant="outlined"
                            sx={{
                              width: "65px",
                            }}
                          >
                            Submit
                          </LoadingButton>
                        </Stack>
                      ) : quantity >= 0 ? (
                        <Button
                          aria-label="box"
                          startIcon={
                            <img
                              style={{ width: "24px", height: "24px" }}
                              src="./available_status.svg"
                              alt="product status icon"
                            />
                          }
                          sx={{
                            minWidth: "40px",
                            ml: 2,
                            height: "33px",
                            padding: "4px 2px !important",
                            background: quantity < 1 ? "#F33" : "#0C0",
                            "& .css-1d6wzja-MuiButton-startIcon": {
                              marginRight: "0px !important",
                              marginLeft: "0px !important",
                            },
                            borderRadius: 0,
                            ":hover": {
                              background: quantity < 1 ? "#F33" : "#0C0",
                            },
                          }}
                          onMouseEnter={handlePopoverOpen}
                          onMouseLeave={handlePopoverClose}
                        ></Button>
                      ) : (
                        <></>
                      )}

                      {erpProduct && quantity !== -1 && (
                        <Popover
                          id="mouse-over-popover"
                          sx={{
                            pointerEvents: "none",
                          }}
                          open={open}
                          anchorEl={anchorEl}
                          anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "center",
                          }}
                          transformOrigin={{
                            vertical: "top",
                            horizontal: "center",
                          }}
                          onClose={handlePopoverClose}
                          disableRestoreFocus
                        >
                          <MenuList
                            onMouseEnter={handlePopoverOpen}
                            onMouseLeave={handlePopoverClose}
                          >
                            {erpProduct
                            .filter((warehouse: any) => warehouse.KMAG !== "M003/1" && warehouse.KMAG !== "M005")
                            .map((warehouse: any, index: any) => (
                              <MenuItem key={index}>
                                <span
                                  style={{
                                    background:
                                      warehouse.GJENDJE < 1 ? "#F33" : "#0C0",
                                    width: "10px",
                                    height: "10px",
                                    borderRadius: "15px",
                                  }}
                                ></span>
                                <Typography
                                  sx={{
                                    fontSize: "13px",
                                    fontFamily: "Inter",
                                    fontWeight: 500,
                                    display: "flex",
                                    alignItems: "center",
                                    ml: 1,
                                  }}
                                >
                                  {warehouse.KMAG === "M001"
                                    ? "Magazina 21"
                                    : warehouse.KMAG === "M002"
                                    ? "Magazina unaze e re"
                                    : warehouse.KMAG === "M003"
                                    ? "Magazina kryesore"
                                    : warehouse.KMAG}
                                </Typography>
                              </MenuItem>
                            ))}
                          </MenuList>
                        </Popover>
                      )}
                      <TextField
                        disabled={quantity < 1}
                        variant="outlined"
                        size="small"
                        value={value}
                        onChange={handleChange}
                        sx={{
                          width: "45px",
                          "& .MuiInputBase-root": {
                            height: "35px",
                          },
                          "& .MuiOutlinedInput-input": {
                            padding: "0",
                            width: "40px",
                            textAlign: "center",
                            height: "35px",
                          },
                          background: "#fff",
                        }}
                      />
                      <IconButton
                        aria-label="shopping cart"
                        disabled={quantity < 1 || !value}
                        size="medium"
                        sx={{
                          background: theme.palette.secondary.main,
                          borderRadius: 0,
                          ":hover": {
                            backgroundColor: "#df0219",
                          },
                        }}
                        onClick={() =>
                          addToBasket(article, {
                            vertical: "top",
                            horizontal: "right",
                          })
                        }
                      >
                        <ShoppingCartOutlinedIcon
                          sx={{ color: "#fff", fontSize: "17px" }}
                        />
                      </IconButton>
                    </Box>
                  }
                  title={article.articleNumber}
                  subheader={article.internalNumber}
                />
                <CardMedia
                  component="img"
                  height="194"
                  image={article.images[0]?.imageURL800 || defaultImage}
                  alt="Paella dish"
                  sx={{ objectFit: "contain" }}
                />
                <CardContent>
                  <div>
                    {retailPrice ? (
                      price_2 > 0 && (
                        <>
                          <Typography
                            variant="h6"
                            sx={{
                              float: "left",
                              fontSize: "15px",
                              fontFamily: "Inter",
                              fontWeight: "normal",
                              width: "max-content",
                            }}
                          >
                            Retail Price
                          </Typography>
                          <Typography
                            variant="h6"
                            sx={{
                              float: "right",
                              color: theme.palette.secondary.main,
                              fontSize: "15px",
                              fontFamily: "Inter",
                              fontWeight: "bold",
                              width: "max-content",
                            }}
                          >
                            {price_2.toFixed(2)} LEK
                          </Typography>
                        </>
                      )
                    ) : (
                      <>
                        <Typography
                          variant="h6"
                          sx={{
                            float: "left",
                            fontSize: "15px",
                            fontFamily: "Inter",
                            fontWeight: "normal",
                            width: "max-content",
                          }}
                        >
                          Price
                        </Typography>
                        <Typography
                          variant="h6"
                          sx={{
                            float: "right",
                            color: theme.palette.secondary.main,
                            fontSize: "15px",
                            fontFamily: "Inter",
                            fontWeight: "bold",
                            width: "max-content",
                          }}
                        >
                          {price.toFixed(2)} LEK
                        </Typography>
                      </>
                    )}
                  </div>
                  <div style={{ marginTop: "30px" }}>
                    <Typography
                      sx={{
                        textAlign: "left",
                        fontSize: "12px",
                        fontFamily: "Inter",
                        fontWeight: 500,
                      }}
                    >
                      {article.mfrName}
                    </Typography>
                  </div>
                  <div>
                    <Typography
                      sx={{
                        textAlign: "left",
                        fontSize: "12px",
                        fontFamily: "Inter",
                        fontWeight: "normal",
                      }}
                    >
                      {article.genericArticles[0]?.genericArticleDescription}
                    </Typography>
                  </div>
                  <div>
                    <Typography
                      sx={{
                        textAlign: "left",
                        fontSize: "12px",
                        fontFamily: "Inter",
                        fontWeight: "normal",
                      }}
                    >
                      Found via{" "}
                      <b style={{ fontWeight: 500 }}>Article number</b> (
                      {article.articleNumber})
                    </Typography>
                  </div>
                  <div>
                    <Typography
                      sx={{
                        textAlign: "left",
                        fontSize: "12px",
                        fontFamily: "Inter",
                        fontWeight: "normal",
                      }}
                    >
                      {article.articleCriteria[0]?.criteriaDescription && (
                        <>
                          <b style={{ fontWeight: 500 }}>
                            {article.articleCriteria[0].criteriaDescription}
                          </b>
                          : {article.articleCriteria[0]?.formattedValue};{" "}
                        </>
                      )}
                      {article.articleCriteria[1]?.criteriaDescription && (
                        <>
                          <b style={{ fontWeight: 500 }}>
                            {article.articleCriteria[1].criteriaDescription}
                          </b>
                          : {article.articleCriteria[1]?.formattedValue};{" "}
                        </>
                      )}
                      {article.articleCriteria[2]?.criteriaDescription && (
                        <>
                          <b style={{ fontWeight: 500 }}>
                            {article.articleCriteria[2].criteriaDescription}
                          </b>
                          : {article.articleCriteria[2]?.formattedValue};{" "}
                        </>
                      )}
                      {article.articleCriteria[3]?.criteriaDescription && (
                        <>
                          <b style={{ fontWeight: 500 }}>
                            {article.articleCriteria[3].criteriaDescription}
                          </b>
                          : {article.articleCriteria[3]?.formattedValue};{" "}
                        </>
                      )}
                      {article.articleCriteria[4]?.criteriaDescription && (
                        <>
                          <b style={{ fontWeight: 500 }}>
                            {article.articleCriteria[4].criteriaDescription}
                          </b>
                          : {article.articleCriteria[4]?.formattedValue};{" "}
                        </>
                      )}
                      {article.articleCriteria[5]?.criteriaDescription && (
                        <>
                          <b style={{ fontWeight: 500 }}>
                            {article.articleCriteria[5].criteriaDescription}
                          </b>
                          : {article.articleCriteria[5]?.formattedValue};{" "}
                        </>
                      )}
                      {article.articleCriteria[6]?.criteriaDescription && (
                        <>
                          <b style={{ fontWeight: 500 }}>
                            {article.articleCriteria[6].criteriaDescription}
                          </b>
                          : {article.articleCriteria[6]?.formattedValue};{" "}
                        </>
                      )}
                    </Typography>
                  </div>
                </CardContent>
                <CardActions disableSpacing>
                  <ProductTabs
                    article={article}
                    qty={value}
                    isFavorite={isFavorite}
                  />
                </CardActions>
              </Card>
            </>
          ) : (
            <>
              <Grid container spacing={0}>
                <Grid
                  item
                  xs={12}
                  sx={{
                    border: "4px solid #f1f1f1",
                    marginBottom: 0.5,
                    padding: 0.5,
                  }}
                >
                  <Box
                    sx={{
                      background: "#f1f1f1",
                      pt: 0.5,
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Item
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Button
                        sx={{
                          minWidth: "40px",
                          "&:focus, &:active": {
                            backgroundColor: "transparent",
                          },
                        }}
                        startIcon={<CustomIcon />}
                        onClick={handleClick(SlideTransition)}
                      ></Button>
                      <Typography
                        sx={{
                          fontSize: "15px",
                          fontFamily: "Inter",
                          fontWeight: 500,
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        {article.articleNumber}
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "15px",
                          ml: 2,
                          color: "#F33",
                          fontFamily: "Inter",
                          fontWeight: 500,
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        {article.internalNumber}
                      </Typography>
                    </Item>
                    <Item
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Tooltip title="Check to compare">
                        <Checkbox
                          onChange={handleCheckboxChange}
                          inputProps={{ "aria-label": "controlled" }}
                          sx={{
                            textTransform: "capitalize",
                            color: theme.palette.primary.main,
                            mr: 1,
                            "&.Mui-checked": {
                              color: theme.palette.primary.main,
                            },
                            display: "flex",
                            flexDirection: "row",
                          }}
                        />
                      </Tooltip>
                      {isloadingStock ? (
                        <Skeleton
                          variant="text"
                          sx={{ fontSize: "1rem", width: "60px", mr: 2 }}
                        />
                      ) : retailPrice ? (
                        price_2 > 0 && (
                          <>
                            <Box
                              sx={{
                                display: "flex",
                                mr: 2,
                                flexDirection: "column", // Ensures vertical layout
                                alignItems: "flex-start", // Align text to the start (left-aligned)
                              }}
                            >
                              <Typography
                                sx={{
                                  fontSize: "15px",
                                  fontFamily: "Inter",
                                  fontWeight: "normal",
                                }}
                              >
                                {price_2.toFixed(2)} LEK
                              </Typography>

                              <Typography
                                sx={{
                                  fontSize: "10px",
                                  fontFamily: "Inter",
                                  fontWeight: "normal",
                                }}
                              >
                                Retail price
                              </Typography>
                            </Box>
                          </>
                        )
                      ) : (
                        price > 0 && (
                          <Typography
                            sx={{
                              fontSize: "15px",
                              fontFamily: "Inter",
                              fontWeight: "normal",
                              display: "flex",
                              alignItems: "center",
                              mr: 2,
                            }}
                          >
                            {price.toFixed(2)} LEK
                          </Typography>
                        )
                      )}

                      {isloadingStock ? (
                        <Stack
                          direction="row"
                          spacing={2}
                          sx={{ width: "65px" }}
                        >
                          <LoadingButton
                            loading
                            variant="outlined"
                            sx={{
                              width: "65px",
                            }}
                          >
                            Submit
                          </LoadingButton>
                        </Stack>
                      ) : quantity >= 0 ? (
                        <Button
                          startIcon={
                            <img
                              style={{ width: "27px", height: "27px" }}
                              src="./available_status.svg"
                              alt="product status icon"
                            />
                          }
                          sx={{
                            padding: "4px 2px !important",
                            background: quantity < 1 ? "#F33" : "#0C0",
                            "& .css-1d6wzja-MuiButton-startIcon": {
                              marginRight: "0px !important",
                              marginLeft: "0px !important",
                            },
                            borderRadius: 0,
                            ":hover": {
                              background: quantity < 1 ? "#F33" : "#0C0",
                            },
                          }}
                          onMouseEnter={handlePopoverOpen}
                          onMouseLeave={handlePopoverClose}
                        ></Button>
                      ) : (
                        <></>
                      )}

                      {erpProduct && quantity !== -1 && (
                        <Popover
                          id="mouse-over-popover"
                          sx={{
                            pointerEvents: "none",
                          }}
                          open={open}
                          anchorEl={anchorEl}
                          anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "center",
                          }}
                          transformOrigin={{
                            vertical: "top",
                            horizontal: "center",
                          }}
                          onClose={handlePopoverClose}
                          disableRestoreFocus
                        >
                          <MenuList
                            onMouseEnter={handlePopoverOpen}
                            onMouseLeave={handlePopoverClose}
                          >
                            {erpProduct
                            .filter((warehouse: any) => warehouse.KMAG !== "M003/1" && warehouse.KMAG !== "M005")
                            .map((warehouse: any, index: any) => (
                              <MenuItem key={index}>
                                <span
                                  style={{
                                    background:
                                      warehouse.GJENDJE < 1 ? "#F33" : "#0C0",
                                    width: "10px",
                                    height: "10px",
                                    borderRadius: "15px",
                                  }}
                                ></span>
                                <Typography
                                  sx={{
                                    fontSize: "13px",
                                    fontFamily: "Inter",
                                    fontWeight: 500,
                                    display: "flex",
                                    alignItems: "center",
                                    ml: 1,
                                  }}
                                >
                                  {warehouse.KMAG === "M001"
                                    ? "Magazina 21"
                                    : warehouse.KMAG === "M002"
                                    ? "Magazina unaze e re"
                                    : warehouse.KMAG === "M003"
                                    ? "Magazina kryesore"
                                    : warehouse.KMAG}
                                </Typography>
                              </MenuItem>
                            ))}
                          </MenuList>
                        </Popover>
                      )}
                      <TextField
                        disabled={quantity < 1}
                        placeholder="1"
                        id="standard-number"
                        size="medium"
                        variant="standard"
                        value={value}
                        onChange={handleChange}
                        sx={{
                          width: "50px",
                          background: "#fff",
                          marginLeft: 2,
                          marginRight: 2,
                          "& .MuiInput-underline:before": {
                            borderBottom: "none", // Remove the bottom border before hover
                          },
                          "& .MuiInput-underline:hover:not(.Mui-disabled):before":
                            {
                              borderBottom: "none", // Remove the bottom border on hover
                            },
                          "& .MuiInput-underline:after": {
                            borderBottom: "none", // Remove the bottom border after clicking
                          },
                        }}
                        InputProps={{
                          sx: {
                            textAlign: "center",
                            border: "1px solid #d4d4d4",
                            height: "36px",
                            fontSize: "13px",
                            fontFamily: "Inter",
                            paddingLeft: ".9rem",
                            width: "100%",
                            "& .MuiInput-underline:hover:not(.Mui-disabled):before":
                              {
                                borderBottom: "none", // Remove the bottom border on hover
                              },
                            "& .MuiInput-underline:after": {
                              borderBottom: "none", // Remove the bottom border after clicking
                            },
                          },
                        }}
                      />
                      <Button
                        disabled={quantity < 1 || !value}
                        aria-label="basket"
                        size="medium"
                        sx={{
                          background: theme.palette.secondary.main,
                          borderRadius: 0,
                          ":hover": {
                            backgroundColor: "#df0219",
                          },
                        }}
                        onClick={() =>
                          addToBasket(article, {
                            vertical: "top",
                            horizontal: "right",
                          })
                        }
                      >
                        <ShoppingCartOutlinedIcon
                          sx={{ color: "#fff" }}
                          fontSize="medium"
                        />
                      </Button>
                    </Item>
                  </Box>
                  <Box sx={{ display: "flex" }}>
                    <Item
                      sx={{
                        flex: 1,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <img
                        style={{ width: "100px" }}
                        src={article.images[0]?.imageURL200 || defaultImage}
                        alt="Product"
                      />
                    </Item>
                    <Item
                      sx={{
                        flex: 11,
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "flex-start",
                      }}
                    >
                      <div>
                        <Typography
                          sx={{
                            textAlign: "left",
                            fontSize: "13px",
                            fontFamily: "Inter",
                            fontWeight: 500,
                          }}
                        >
                          {article.mfrName}
                        </Typography>
                      </div>
                      <div>
                        <Typography
                          sx={{
                            textAlign: "left",
                            fontSize: "13px",
                            fontFamily: "Inter",
                            fontWeight: "normal",
                          }}
                        >
                          {
                            article.genericArticles[0]
                              ?.genericArticleDescription
                          }
                        </Typography>
                      </div>
                      <div>
                        <Typography
                          sx={{
                            textAlign: "left",
                            fontSize: "13px",
                            fontFamily: "Inter",
                            fontWeight: "normal",
                          }}
                        >
                          Found via{" "}
                          <b style={{ fontWeight: 500 }}>Article number</b> (
                          {article.articleNumber})
                        </Typography>
                      </div>
                      <div>
                        <Typography
                          sx={{
                            textAlign: "left",
                            fontSize: "13px",
                            fontFamily: "Inter",
                            fontWeight: "normal",
                          }}
                        >
                          {article.articleCriteria[0]?.criteriaDescription && (
                            <>
                              <b style={{ fontWeight: 500 }}>
                                {article.articleCriteria[0].criteriaDescription}
                              </b>
                              : {article.articleCriteria[0]?.formattedValue};{" "}
                            </>
                          )}
                          {article.articleCriteria[1]?.criteriaDescription && (
                            <>
                              <b style={{ fontWeight: 500 }}>
                                {article.articleCriteria[1].criteriaDescription}
                              </b>
                              : {article.articleCriteria[1]?.formattedValue};{" "}
                            </>
                          )}
                          {article.articleCriteria[2]?.criteriaDescription && (
                            <>
                              <b style={{ fontWeight: 500 }}>
                                {article.articleCriteria[2].criteriaDescription}
                              </b>
                              : {article.articleCriteria[2]?.formattedValue};{" "}
                            </>
                          )}
                          {article.articleCriteria[3]?.criteriaDescription && (
                            <>
                              <b style={{ fontWeight: 500 }}>
                                {article.articleCriteria[3].criteriaDescription}
                              </b>
                              : {article.articleCriteria[3]?.formattedValue};{" "}
                            </>
                          )}
                          {article.articleCriteria[4]?.criteriaDescription && (
                            <>
                              <b style={{ fontWeight: 500 }}>
                                {article.articleCriteria[4].criteriaDescription}
                              </b>
                              : {article.articleCriteria[4]?.formattedValue};{" "}
                            </>
                          )}
                          {article.articleCriteria[5]?.criteriaDescription && (
                            <>
                              <b style={{ fontWeight: 500 }}>
                                {article.articleCriteria[5].criteriaDescription}
                              </b>
                              : {article.articleCriteria[5]?.formattedValue};{" "}
                            </>
                          )}
                          {article.articleCriteria[6]?.criteriaDescription && (
                            <>
                              <b style={{ fontWeight: 500 }}>
                                {article.articleCriteria[6].criteriaDescription}
                              </b>
                              : {article.articleCriteria[6]?.formattedValue};{" "}
                            </>
                          )}
                        </Typography>
                      </div>
                    </Item>
                  </Box>
                  <Box sx={{ display: "flex" }}>
                    <ProductTabs
                      article={article}
                      qty={value}
                      isFavorite={isFavorite}
                    />
                  </Box>
                </Grid>
              </Grid>
              <Snackbar
                open={state.open}
                onClose={handleClose}
                TransitionComponent={state.Transition}
                key={state.Transition.name}
                autoHideDuration={1200}
              >
                <Alert
                  onClose={handleClose}
                  severity="info"
                  sx={{ width: "100%" }}
                >
                  {article.articleNumber} copied!
                </Alert>
              </Snackbar>

              <Snackbar
                key={vertical + horizontal}
                anchorOrigin={{ vertical, horizontal }}
                open={openNotification}
                autoHideDuration={1500}
                onClose={hideBasketNotification}
              >
                <Alert
                  onClose={hideBasketNotification}
                  severity="success"
                  sx={{
                    bgcolor: "#fff",
                    width: "100%",
                    boxShadow: "4px 5px 5px #cfcfcf",
                  }}
                >
                  <Card sx={{ display: "flex", boxShadow: "none" }}>
                    <CardMedia
                      component="img"
                      sx={{ width: 120 }}
                      image={article.images[0]?.imageURL800 || defaultImage}
                      alt="Live from space album cover"
                    />
                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                      <CardContent sx={{ flex: "1 0 auto" }}>
                        <Typography
                          component="div"
                          variant="subtitle1"
                          sx={{
                            color: theme.palette.text.primary,
                            fontSize: "12px",
                            fontFamily: "Inter",
                            fontWeight: 500,
                          }}
                        >
                          {article.mfrName}{" "}
                          {
                            article.genericArticles[0]
                              ?.genericArticleDescription
                          }
                        </Typography>
                        <Typography
                          variant="subtitle1"
                          color="text.secondary"
                          component="div"
                          sx={{
                            textAlign: "left",
                            color: theme.palette.text.primary,
                            fontSize: "12px",
                            fontFamily: "Inter",
                            fontWeight: 500,
                          }}
                        >
                          Quantity x {value}
                        </Typography>
                        {article.price && (
                          <Typography
                            variant="subtitle1"
                            color="text.secondary"
                            component="div"
                            sx={{
                              textAlign: "left",
                              color: theme.palette.text.primary,
                              fontSize: "12px",
                              fontFamily: "Inter",
                              fontWeight: 500,
                            }}
                          >
                            Price: {article.price.toFixed(2)} LEK
                          </Typography>
                        )}
                      </CardContent>
                    </Box>
                  </Card>
                </Alert>
              </Snackbar>
            </>
          )}
        </>
      ) : (
        <Grid container spacing={0}>
          <Grid
            item
            xs={12}
            sx={{
              border: "4px solid #f1f1f1",
              marginBottom: 0.5,
              padding: 0.5,
            }}
          >
            <Box
              sx={{
                background: "#f1f1f1",
                pt: 0.5,
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Item
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Skeleton
                  animation="wave"
                  sx={{ width: "30px", height: "30px", mr: 1 }}
                />
                <Skeleton
                  animation="wave"
                  sx={{ width: "80px", height: "30px" }}
                />
              </Item>
              <Item
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Tooltip title="Check to compare">
                  <Skeleton
                    animation="wave"
                    sx={{ width: "30px", height: "30px", mr: 1 }}
                  />
                </Tooltip>
                <Skeleton
                  animation="wave"
                  sx={{ width: "80px", height: "30px", mr: 1 }}
                />
                <Skeleton
                  animation="wave"
                  sx={{ width: "30px", height: "30px", mr: 1 }}
                />
                <Skeleton
                  animation="wave"
                  sx={{ width: "30px", height: "30px", mr: 1 }}
                />
                <Skeleton
                  animation="wave"
                  sx={{ width: "30px", height: "30px" }}
                />
              </Item>
            </Box>
            <Box sx={{ display: "flex" }}>
              <Item
                sx={{
                  flex: 1,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Skeleton
                  animation="wave"
                  sx={{ width: "5rem", height: "7rem" }}
                />
              </Item>
              <Item
                sx={{
                  flex: 11,
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-start",
                }}
              >
                <div>
                  <Skeleton animation="wave" sx={{ width: "40px" }} />
                </div>
                <div>
                  <Skeleton animation="wave" sx={{ width: "50px" }} />
                </div>
                <div>
                  <Skeleton animation="wave" sx={{ width: "40rem" }} />
                </div>
                <div>
                  <Skeleton animation="wave" sx={{ width: "30rem" }} />
                  <Skeleton animation="wave" sx={{ width: "35rem" }} />
                  <Skeleton animation="wave" sx={{ width: "30rem" }} />
                </div>
              </Item>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              border: "4px solid #f1f1f1",
              marginBottom: 0.5,
              padding: 0.5,
            }}
          >
            <Box
              sx={{
                background: "#f1f1f1",
                pt: 0.5,
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Item
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Skeleton
                  animation="wave"
                  sx={{ width: "30px", height: "30px", mr: 1 }}
                />
                <Skeleton
                  animation="wave"
                  sx={{ width: "80px", height: "30px" }}
                />
              </Item>
              <Item
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Tooltip title="Check to compare">
                  <Skeleton
                    animation="wave"
                    sx={{ width: "30px", height: "30px", mr: 1 }}
                  />
                </Tooltip>
                <Skeleton
                  animation="wave"
                  sx={{ width: "80px", height: "30px", mr: 1 }}
                />
                <Skeleton
                  animation="wave"
                  sx={{ width: "30px", height: "30px", mr: 1 }}
                />
                <Skeleton
                  animation="wave"
                  sx={{ width: "30px", height: "30px", mr: 1 }}
                />
                <Skeleton
                  animation="wave"
                  sx={{ width: "30px", height: "30px" }}
                />
              </Item>
            </Box>
            <Box sx={{ display: "flex" }}>
              <Item
                sx={{
                  flex: 1,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Skeleton
                  animation="wave"
                  sx={{ width: "5rem", height: "7rem" }}
                />
              </Item>
              <Item
                sx={{
                  flex: 11,
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-start",
                }}
              >
                <div>
                  <Skeleton animation="wave" sx={{ width: "40px" }} />
                </div>
                <div>
                  <Skeleton animation="wave" sx={{ width: "50px" }} />
                </div>
                <div>
                  <Skeleton animation="wave" sx={{ width: "40rem" }} />
                </div>
                <div>
                  <Skeleton animation="wave" sx={{ width: "30rem" }} />
                  <Skeleton animation="wave" sx={{ width: "35rem" }} />
                  <Skeleton animation="wave" sx={{ width: "30rem" }} />
                </div>
              </Item>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              border: "4px solid #f1f1f1",
              marginBottom: 0.5,
              padding: 0.5,
            }}
          >
            <Box
              sx={{
                background: "#f1f1f1",
                pt: 0.5,
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Item
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Skeleton
                  animation="wave"
                  sx={{ width: "30px", height: "30px", mr: 1 }}
                />
                <Skeleton
                  animation="wave"
                  sx={{ width: "80px", height: "30px" }}
                />
              </Item>
              <Item
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Tooltip title="Check to compare">
                  <Skeleton
                    animation="wave"
                    sx={{ width: "30px", height: "30px", mr: 1 }}
                  />
                </Tooltip>
                <Skeleton
                  animation="wave"
                  sx={{ width: "80px", height: "30px", mr: 1 }}
                />
                <Skeleton
                  animation="wave"
                  sx={{ width: "30px", height: "30px", mr: 1 }}
                />
                <Skeleton
                  animation="wave"
                  sx={{ width: "30px", height: "30px", mr: 1 }}
                />
                <Skeleton
                  animation="wave"
                  sx={{ width: "30px", height: "30px" }}
                />
              </Item>
            </Box>
            <Box sx={{ display: "flex" }}>
              <Item
                sx={{
                  flex: 1,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Skeleton
                  animation="wave"
                  sx={{ width: "5rem", height: "7rem" }}
                />
              </Item>
              <Item
                sx={{
                  flex: 11,
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-start",
                }}
              >
                <div>
                  <Skeleton animation="wave" sx={{ width: "40px" }} />
                </div>
                <div>
                  <Skeleton animation="wave" sx={{ width: "50px" }} />
                </div>
                <div>
                  <Skeleton animation="wave" sx={{ width: "40rem" }} />
                </div>
                <div>
                  <Skeleton animation="wave" sx={{ width: "30rem" }} />
                  <Skeleton animation="wave" sx={{ width: "35rem" }} />
                  <Skeleton animation="wave" sx={{ width: "30rem" }} />
                </div>
              </Item>
            </Box>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default ProductItem;
