import axiosInstance from "../components/auth/interceptor";
import { 
  TaskStatus, 
  StartVerificationResponse 
} from '../interfaces/core/ArticleVerification';

const baseURL = process.env.REACT_APP_API_URL;

export const verificationService = {
   async startVerification(file: File): Promise<StartVerificationResponse> {
     const formData = new FormData();
     formData.append('file', file);
 
     const response = await axiosInstance.post<StartVerificationResponse>(
       `${baseURL}/verify-articles/start`, 
       formData,
       {
         headers: {
           'Content-Type': 'multipart/form-data',
         },
         timeout: 2000,
       }
     );
     return response.data;
   },
 
   async getVerificationStatus(taskId: string): Promise<TaskStatus> {
     const response = await axiosInstance.get<TaskStatus>(
       `${baseURL}/verify-articles/status/${taskId}`
     );
     return response.data;
   },
 };