import * as React from "react";
import AppBar from "@mui/material/AppBar";
import MobileSideMenu from "./MobileSideMenu";
import Badge, { BadgeProps } from "@mui/material/Badge";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import MenuItem from "@mui/material/MenuItem";
import { useSelector, useDispatch } from "react-redux";
import Menu from "@mui/material/Menu";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import ListItemIcon from "@mui/material/ListItemIcon";
import MenuIcon from "@mui/icons-material/Menu";
import { Logout } from "@mui/icons-material";
import Button from "../../ui/Button";
import { Divider, Drawer } from "@mui/material";
import DiscountNotificationBanner from "./DiscountNotificationBanner";
import { RootState } from "../../../store/store";
import { getBasketProducts } from "../../../services/core-api";
import { setCartItems } from "../../../store/reducers/basket";

const StyledBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
  "& .MuiBadge-badge": {
    right: -3,
    top: 1,
    background: theme.palette.secondary.main,
    color: theme.palette.text.secondary,
    border: `1px solid ${theme.palette.background.paper}`,
    padding: "0 4px",
  },
}));

const MobileMenu = () => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const cartItems = useSelector((state: RootState) => state.cart.items);
  const dispatch = useDispatch();
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const hasFetchedRef = React.useRef(false);

  React.useEffect(() => {
    if (!hasFetchedRef.current) {
      const fetchBasketData = async () => {
        const basket = await getBasketProducts();
        if (basket) {
          dispatch(setCartItems(basket.items));
        }
      };

      fetchBasketData();
      hasFetchedRef.current = true; 
    }
  }, [dispatch]);

  const [openSideMenu, setOpenSideMenu] = React.useState(false);

  const toggleDrawer = (newOpen: boolean) => () => {
    setOpenSideMenu(newOpen);
  };

  const handleNavigatoToBasket = () => {
    window.location.href = "/basket";
  }

  const itemCount = cartItems?.length || 0;

  return (
    <>
      <Box
        sx={{ flexGrow: 1, display: { xs: "block", sm: "block", md: "none" } }}
      >
        <AppBar position="static" sx={{ boxShadow: "none", height: "47px" }}>
          <Toolbar
            sx={{
              minHeight: "45px !important",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="menu"
              onClick={toggleDrawer(true)}
              sx={{ mr: 1 }}
            >
              <MenuIcon />
            </IconButton>
            <Box>
              <img
                style={{ height: "45px", width: "auto" }}
                src="./logo.png"
                alt="Logo"
              />
            </Box>
            <Button
              color="inherit"
              onClick={handleClick}
              size="small"
              sx={{ ml: 2, display: { xs: "none", sm: "none", md: "flex" }, alignItems: "center" }}
              aria-controls={open ? "account-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
            >
              <Avatar
                sx={{
                  width: 20,
                  height: 20,
                  display: { xs: "none", sm: "none", md: "block" },
                }}
              ></Avatar>
              <Typography
                variant="caption"
                color="text.secondary"
                component="div"
                sx={{
                  marginLeft: 1,
                  display: { xs: "none", sm: "none", md: "block" },
                }}
              >
                My Aftermarket
              </Typography>
            </Button>
            {/* Add your additional IconButton here */}
            
            <DiscountNotificationBanner/>
           
            <IconButton
              aria-label="cart"
              sx={{ display: { xs: "block", sm: "block", md: "none" } }}
              onClick={handleNavigatoToBasket}
            >
              <StyledBadge badgeContent={itemCount}>
                <ShoppingCartIcon sx={{ color: "primary.light" }} />
              </StyledBadge>
            </IconButton>
          </Toolbar>
        </AppBar>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&::before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem onClick={handleClose}>My Orders</MenuItem>
        <MenuItem onClick={handleClose}>Balance</MenuItem>
        <MenuItem onClick={handleClose}>Returns and Claims</MenuItem>
        <MenuItem onClick={handleClose}>Favorite Products</MenuItem>
        <MenuItem onClick={handleClose}>Promotions</MenuItem>
        <MenuItem onClick={handleClose}>Offers</MenuItem>
        <Divider />
        <MenuItem onClick={handleClose}>
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          Logout
        </MenuItem>
      </Menu>
      <Drawer open={openSideMenu} onClose={toggleDrawer(false)}>
        <Box
          sx={{ width: 250 }}
          role="presentation"
          onClick={toggleDrawer(false)}
        >
          <MobileSideMenu></MobileSideMenu>
        </Box>
      </Drawer>
    </>
  );
};

export default MobileMenu;
