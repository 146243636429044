import React from 'react';
import { Button, Box, Alert } from '@mui/material';
import * as XLSX from 'xlsx';
import Papa from 'papaparse';

interface FileUploadProps {
  onFileSelect: (file: File) => void;
}

const MAX_ROWS = 105;

const FileUpload: React.FC<FileUploadProps> = ({ onFileSelect }) => {
  const [error, setError] = React.useState<string | null>(null);

  const validateFileRows = async (file: File): Promise<boolean> => {
    return new Promise((resolve) => {
      if (file.name.toLowerCase().endsWith('.csv')) {
        // CSV validation
        Papa.parse(file, {
          complete: (results: any) => {
            if (results.data.length > MAX_ROWS) {
              setError(`File contains ${results.data.length - 1} rows. Maximum allowed is ${MAX_ROWS} rows.`);
              resolve(false);
            } else {
              setError(null);
              resolve(true);
            }
          },
          error: (error: any) => {
            setError(`Error reading file: ${error.message}`);
            resolve(false);
          }
        });
      } else {
        // Excel validation
        const reader = new FileReader();
        
        reader.onload = (e) => {
          try {
            const data = new Uint8Array(e.target?.result as ArrayBuffer);
            const workbook = XLSX.read(data, { type: 'array' });
            
            const firstSheet = workbook.Sheets[workbook.SheetNames[0]];
            const jsonData = XLSX.utils.sheet_to_json(firstSheet);
            
            if (jsonData.length > MAX_ROWS) {
              setError(`File contains ${jsonData.length} rows. Maximum allowed is ${MAX_ROWS} rows.`);
              resolve(false);
            } else {
              setError(null);
              resolve(true);
            }
          } catch (err) {
            setError('Error reading Excel file');
            resolve(false);
          }
        };

        reader.onerror = () => {
          setError('Error reading file');
          resolve(false);
        };

        reader.readAsArrayBuffer(file);
      }
    });
  };

  const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    setError(null);

    if (file) {
      const fileType = file.name.toLowerCase();
      if (!fileType.endsWith('.csv') && !fileType.endsWith('.xlsx') && !fileType.endsWith('.xls')) {
        setError('Please upload only CSV or Excel files');
        return;
      }

      const isValid = await validateFileRows(file);
      if (isValid) {
        onFileSelect(file);
      }
    }
  };

  return (
    <Box>
      {error && (
        <Alert severity="error" sx={{ mb: 2 }}>
          {error}
        </Alert>
      )}
      <input
        accept=".csv,.xlsx,.xls"
        style={{ display: 'none' }}
        id="raised-button-file"
        type="file"
        onChange={handleFileChange}
      />
      <label htmlFor="raised-button-file">
        <Button variant="contained" component="span">
          Upload File (Max {MAX_ROWS} rows)
        </Button>
      </label>
    </Box>
  );
};

export default FileUpload;